/**
 * @packageDocumentation
 * @module utils
 */
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import * as E from "fp-ts/lib/Either";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/pipeable";
import { identity, constant, flow, tuple } from "fp-ts/lib/function";
import { apply } from "./functions";
import { isTruthy } from "./assert";
import { Kind2, URIS2 } from "fp-ts/lib/HKT";
import { Chain2 } from "fp-ts/lib/Chain";

/**
 *
 * @param maybe
 */
export const foldMaybe = <T>(maybe: O.Option<T>) => (base: T) =>
  pipe(maybe, O.fold(constant(base), identity));

/**
 *
 * @param maybe
 */
export const foldValue = <T>(base: T) => (maybe: O.Option<T>) =>
  pipe(maybe, O.fold(constant(base), identity));

/**
 *
 * @param maybe
 */
export const foldString = foldValue("");

/**
 * Run a list of tasks sequentially. (Promise.then)
 */
export const chainTasks = A.array.sequence(T.taskSeq);
export const chainTaskEithers = A.array.sequence(TE.taskEitherSeq);

export const delayTask = (milliseconds = 1000) => <T>(task: T.Task<T>) =>
  pipe(
    task,
    T.chain((_) => () =>
      new Promise<T>((resolve) => setTimeout(() => resolve(_), milliseconds))
    )
  );

export const taskToPromise = <E, A>(task: TE.TaskEither<E, A>) =>
  pipe(task, apply, (_) =>
    _.then(
      flow(
        E.fold(
          (_) => () => Promise.reject(_),
          (_) => () => Promise.resolve(_)
        ),
        apply
      )
    )
  );

export const fromTruthy = <T>(value: T) =>
  isTruthy(value) ? O.some(value) : O.none;

export const chainBoth = <F extends URIS2>(chainable: Chain2<F>) => <E1, A>(
  ma: Kind2<F, E1, A>
) => <E2, B>(mb: Kind2<F, E2, B>) =>
  chainable.chain(ma as Kind2<F, E1 | E2, A>, (_) =>
    chainable.map(mb as Kind2<F, E1 | E2, B>, (__) => tuple(_, __))
  );
