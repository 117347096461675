/**
 * Model: User
 * @packageDocumentation
 * @module user
 * @preferred
 */
import { getter } from "../../utils";

export * from "./types";

/**
 * Given a [[User]] returns it's id
 *
 */
export const getId = getter("id");

/**
 * Given a [[User]] returns it's username
 *
 */
export const getUserFirstName = getter("userFirstname");
export const getUserLastName = getter("userLastname");
export const getUserEmail = getter("userEmail");
export const getPhoneNumber = getter("phoneNumber");
export const getLocale = getter("locale");
export const getDefaultUserLocationId = getter("defaultUserLocationId");
