import { User as UserType, UserCoder as _UserCoder } from "./types";

export * from "./getters";
// export { UserCoder } from './types'

export const UserCoder = _UserCoder;
export type User = UserType;

export type AuthenticatedUser = User & {
  companyId: string;
  username: string;
};
