import * as React from "react";

import { pipe } from "fp-ts/lib/function";
import * as T from "fp-ts/lib/Task";

import history from "history/browser";

import { useLocation } from "../../modules/router/hooks";
import { Event } from "./machine";

import Redirect from "../../modules/router/components/Redirect";

// Routed Components
import { useTranslation } from "react-i18next";
import { apply } from "../../modules/utils";
// import { BMSEnabled } from "../bms-enable";

const BMSEnabled = React.lazy(() =>
  pipe(
    () => import(/* webpackChunkName: "bms-enable" */ "../bms-enable"),
    T.map((_) => {
      return {
        ..._,
        default: _.BMSEnabled,
      };
    }),
    apply
  )
);

const BMSEnable = React.lazy(() =>
  import(/* webpackChunkName: "bms-enable" */ "../bms-enable")
);

const SAADApply = React.lazy(() =>
  import(/* webpackChunkName: "saad-apply" */ "../saad-apply")
);

const BMSLogin = React.lazy(() =>
  import(/* webpackChunkName: "bms-login" */ "../bms-login")
);

const SNSignUp = React.lazy(() =>
  import(
    /* webpackChunkName: "saad-snsignup" */ "../saad-sn-signup/SAADSNSignup"
  )
);

const BMSSignup = React.lazy(() =>
  import(/* webpackChunkName: "bms-signup" */ "../bms-signup")
);

const SAAD = React.lazy(() =>
  import(/* webpackChunkName: "saad-dashboard" */ "../saad/SAAD")
);

export * from "./machine";

export const paths = {
  404: "404",
  home: "home",
  "enable-bms": "enable-bms",
  "bms-enabled": "bms-enabled",
  // contactUs: "contact-us",
  login: "login",
  signup: "signup",
  saad: "saad",
  "saad-apply": "saad-apply",
  "sn-signup/asurion": "sn-signup/asurion",
};

// Comonents
const NotFound = () => {
  const [t] = useTranslation();

  useLocation(paths[404]);
  return (
    <div
      style={{
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "4em",
      }}
    >
      {t("404")}
    </div>
  );
};

const Location = (props: { path: string; children: React.ReactNode }) => {
  useLocation(props.path);

  return <>{props.children}</>;
};

const homeRoute = /saad/.test(history.location.pathname)
  ? "saad"
  : "enable-bms";

const Route = ({ state }: { state: any }) => {
  // { state: State<any, any> }) => {
  if (state.matches(paths.home)) {
    return <Redirect to={paths[homeRoute]} />;
  } else if (state.matches("signup")) {
    return (
      <Location path={"signup"}>
        <BMSSignup />
      </Location>
    );
  } else if (state.matches(paths.login)) {
    return (
      <Location path={paths.login}>
        <BMSLogin resettable />
      </Location>
    );
  } else if (state.matches(paths["enable-bms"])) {
    return (
      <Location path={paths["enable-bms"]}>
        <BMSEnable />
      </Location>
    );
  } else if (state.matches(paths["bms-enabled"])) {
    return (
      <Location path={paths["bms-enabled"]}>
        <BMSEnabled />
      </Location>
    );
  } else if (state.matches(paths["saad"])) {
    return <SAAD />;
  } else if (state.matches(paths["saad-apply"])) {
    return <SAADApply />;
  } else if (state.matches(paths["sn-signup/asurion"])) {
    return <SNSignUp />;
  } else if (state.matches(paths["404"])) {
    return <NotFound />;
  }

  return <Redirect to={paths["404"]} />;
};

const routes = [
  // /users\/(?<userId>\d+)/,
  /login/,
  /enable-bms/,
  /bms-enabled/,
  /saad/,
  /sn-signup\/asurion/,
  /signup/,
  // /contact-us/
];

const makeGotoEventFromUrl = (rawUrl: string): Event => {
  const url = rawUrl.replace(/(^\/)|(\/$)/g, "");

  const { event, match } = routes.reduce(
    (acc, next) => {
      if (acc.match) {
        return acc;
      } else if (next instanceof RegExp) {
        if (next.test(url)) {
          const outcome = next.exec(url);

          if (outcome) {
            const groups = outcome.groups || {};

            const route = Object.keys(groups).reduce((acc2, key) => {
              const value = groups[key];
              return acc2.replace(`/${value}`, "");
            }, outcome.input);
            // .replace(/\//g, ".")
            // .replace(/^\.|\.$/, "");

            return { match: true, event: { type: "GOTO", route, ...groups } };
          }
        }
      }

      return acc;
    },
    { match: false, event: { type: "GOTO", route: "home" } }
  );

  return (match ? event : { ...event, redirected: true }) as Event;
};

export const Router = ({ send, state }: { send: any; state: any }) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    const event = makeGotoEventFromUrl(history.location.pathname);
    send(event);
    setReady(true);
  }, [send]);

  React.useEffect(() => {
    if (ready) {
      try {
        const onPopState = () => {
          const event = makeGotoEventFromUrl(history.location.pathname);
          send(event);
        };

        window.addEventListener("popstate", onPopState);

        return () => {
          window.removeEventListener("popstate", onPopState);
        };
      } finally {
      }
    }
  }, [ready, send]);

  if (!ready) {
    return null;
  }

  return (
    <>
      <Route state={state} />
    </>
  );
};

export default Router;
