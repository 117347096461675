import "./App.scss";

import React, { Suspense } from "react";

// import BMSLogin from "./apps/login";
import BMS from "./apps/bms";

const App: React.FC = () => {
  return (
    <Suspense fallback="...">
      <BMS />
    </Suspense>
  );
};

export default App;
