import classes from "./alert.module.scss";
import copy from "./copy.json";

import * as React from "react";
import { BehaviorSubject } from "rxjs";
import { useObservableState } from "observable-hooks";

import { Alert as AlertComponent } from "../../modules/components/Alert";
import { useTranslation } from "../../modules/utils";
import { Failure, isApiFailure, isErrorFailure } from "../../modules/apis/q";

export type Alert = {
  status: "error" | "warning" | "success";
  node: React.ReactNode;
};

const alerts$ = new BehaviorSubject<Alert[]>([]);

export const alert = (alert: Alert) => {
  alerts$.next([...alerts$.value, alert]);
  return alert;
};

export const clear = (alert?: Alert) =>
  alerts$.next(
    alert ? alerts$.value.filter((current) => current !== alert) : []
  );

export const error = (node: React.ReactNode) =>
  alert({ status: "error", node });

export const warning = (node: React.ReactNode) =>
  alert({ status: "warning", node });

export const success = (node: React.ReactNode) =>
  alert({ status: "success", node });

export const fromFailure = (failure: Failure) => {
  if (isApiFailure(failure)) {
    return failure.error.errors.map((_) => error(_.message));
  } else if (isErrorFailure(failure)) {
    return [error("An unknown error has occured.")];
  }

  return [error("The server responded with an unknown messsage.")];
};

export const Alerts = (props: JSX.IntrinsicElements["div"] = {}) => {
  const [t] = useTranslation();
  const alerts = useObservableState(alerts$, []);

  const list = alerts.map((alert, index) => (
    <AlertComponent key={index} status={alert.status}>
      <div className={classes.alert}>
        <div role="alert">{alert.node}</div>
        <button
          aria-label={t(copy["btn-clear-alert"])}
          title={t(copy["btn-clear-alert"])}
          onClick={() => clear(alert)}
        >
          <svg
            aria-hidden="true"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.58586 8.00008L0.292969 14.293L1.70718 15.7072L8.00008 9.41429L14.293 15.7072L15.7072 14.293L9.41429 8.00008L15.7072 1.70718L14.293 0.292969L8.00008 6.58586L1.70718 0.292969L0.292969 1.70718L6.58586 8.00008Z"
              fill={alert.status === "error" ? "#fff" : "#000"}
            />
          </svg>
        </button>
      </div>
    </AlertComponent>
  ));

  return alerts.length > 0 ? <div {...props}>{list}</div> : null;
};

export default Alerts;
