import "./button.scss";

import * as React from "react";

import {
  Button,
  ButtonProps as RmwcButtonProps,
  ButtonHTMLProps,
} from "@rmwc/button";
import { ComponentProps } from "@rmwc/types";

export * from "@rmwc/button";

export { Button as default } from "@rmwc/button";

export type ButtonProps = ComponentProps<
  RmwcButtonProps,
  ButtonHTMLProps,
  "input"
>;

export const PrimaryButton = (props: ButtonProps & { fullWidth?: boolean }) => (
  <Button
    {...props}
    unelevated
    style={Object.assign(
      props.style || {},
      props.fullWidth ? { width: "100%" } : {}
    )}
  />
);

export const GhostButton = (props: ButtonProps & { danger?: boolean }) => (
  <Button
    {...props}
    outlined
    className="ghost"
    data-state={props.danger ? "danger" : ""}
  />
);

export const SecondaryButton = (props: ButtonProps) => (
  <Button {...props} unelevated theme={["secondaryBg", "onSecondary"]} />
);

export const SelectionButton = (
  props: ButtonProps & { selected: boolean; "aria-labelledby": string }
) => (
  <Button
    {...props}
    outlined
    className="selection-button"
    data-selected={props.selected}
    role="radio"
    aria-checked={props.selected}
    aria-labelledby={props["aria-labelledby"]}
  />
);
