/**
 * @packageDocumentation
 * @module utils
 */
import * as TE from "fp-ts/lib/TaskEither";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import { flow, constant } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";

/**
 * Removes the leading and trailing white space and line terminator characters from a string.
 */
export const trim = (value: string) => value.trim();
export const uppercase = (value: string) => value.toUpperCase();
export const split = (separator: string | RegExp) => (value: string) =>
  value.split(separator);
export const replace = (pattern: string | RegExp) => (replacement: string) => (
  value: string
) => value.replace(pattern, replacement);

/**
 *
 * @param str phone number string to be formatted
 * @param args
 *
 * Example:
 *
 * inputString = (888)8888888
 *
 * output = 888-888-8888
 *
 *
 */

export const formatPhoneNumber = (phoneNumberString: string) => {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match ? match[1] + "-" + match[2] + "-" + match[3] : null;
};

/**
 * format a string similar to how `console.log('Hello %s',  'World')` works.
 *
 * Example:
 *
 * ```ts
 * const Counter = (props: { count: number, total: number }) => {
 *  const [t] = useTranslation()
 *
 *  return <span>{t('You have %1 messages of which %0 are read.', props.total, props.count)}</span>
 * }
 * ```
 *
 * @param str The string to be formatted.
 * @param args Any additional arguments are used to replace "variables" within the string.
 */
export const format = (str: string, ...args: any[]) => {
  return str.replace(/%(\d+)/g, (match, number) => {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

export const replaceRx = (searchRx: RegExp) => (replaceValue: string) => (
  str: string
) => str.replace(searchRx, replaceValue);

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    /* eslint-disable-next-line no-mixed-operators */
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const toBase64 = (
  file: File
): TE.TaskEither<ProgressEvent<FileReader> | Error, string> => () =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () =>
      typeof reader.result === "string"
        ? resolve(
            E.right(
              pipe(reader.result, split(","), A.last, O.getOrElse(constant("")))
            )
          )
        : resolve(E.left(Error("Not a string")));

    reader.onerror = flow(E.left, resolve);
  });

export const stringify = <T>(data: T) =>
  process.env.NODE_ENV === "development"
    ? JSON.stringify(data, null, 2)
    : JSON.stringify(data);

export const digitsOnly = replace(/[^\d]/g)("");

export const charCodeAt = (index = 0) => (str: string) => str.charCodeAt(index);
