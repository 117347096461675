import classes from "./bms.module.scss";

import * as React from "react";
import { useObservableState } from "observable-hooks";
import { useMachine } from "@xstate/react";

import { useTranslation } from "../../modules/utils";
import { SendContext } from "../../modules/components/SendContext";

import Router, { machine } from "../bms-router";
import { useServiceLogger } from "../../modules/xstate";
import { isAuthenticated$, logout } from "../../modules/streams/authentication";
// import Link from "../../modules/router/components/Link";
// import { useObservableState } from "observable-hooks";
// import { isAuthenticated$ } from "../../modules/streams/authentication";
import { SecondaryButton } from "../../modules/components/input-controls/Button";

import Alerts from "../alert";

import serviceBenchLogo from "./images/sb-logo.svg";
import serviceBenchTextLogo from "./images/sb-text-logo-white.svg";

import copy from "./copy.json";

// const getScrollTop = () => document.getElementById('root')!.scrollTop  || 0

export const BMS = () => {
  const [state, send, service] = useMachine(machine);
  const [t] = useTranslation();
  const isAuthenticated = useObservableState(isAuthenticated$);

  // const ref = React.useRef<HTMLElement | null>(null)
  // const [scrollTop, setScrollTop] = React.useState(getScrollTop())

  // React.useEffect(() => {
  //     const onScroll = () => {
  //       const nextSrollTop = getScrollTop()
  //       nextSrollTop < 56 && setScrollTop(nextSrollTop)
  //     }

  //     document.addEventListener('scroll', onScroll, { capture: true})

  //     return () => document.removeEventListener('scroll', onScroll, { capture: true})
  // }, [scrollTop])

  useServiceLogger(service, "bms.router");

  // const isAuthenticated = useObservableState(isAuthenticated$, false);

  // const loginLink =
  //   !isAuthenticated && !state.matches("login") ? (
  //     <Link active={state.matches("login")} to="login">
  //       {t(copy.menuLinkLogin)}
  //     </Link>
  //   ) : null;

  // const maxTop = scrollTop > 56 ? 56 : scrollTop
  // const scaleR = 1 - 1 * maxTop / 106;

  return (
    <SendContext.Provider value={{ state, send }}>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <nav
        className={`sb-theme ${classes.nav}`}
        style={
          {
            // top: 0 - maxTop
          }
        }
      >
        <div
          className={classes.logos}
          style={
            {
              /* 
          transform: `translateY(${maxTop / 2}px) scale(${scaleR})`,
          transformOrigin: 'left center'
         */
            }
          }
        >
          <img
            className={classes.logo}
            alt="ServiceBench"
            title="ServiceBench (logo)"
            aria-hidden="true"
            src={serviceBenchLogo}
          />
          <img
            className={classes.textLogo}
            alt="ServiceBench"
            title="ServiceBench (logo)"
            src={serviceBenchTextLogo}
          />
        </div>

        <div
          className={classes.navRight}
          style={
            {
              /* 
          transform: `translateY(${maxTop / 2}px) scale(${scaleR})`,
          transformOrigin: 'right center'
        */
            }
          }
        >
          {isAuthenticated ? (
            <SecondaryButton
              onClick={() => {
                send({ type: "GOTO", route: "home" });
                logout();
              }}
            >
              {t(copy.logoutButtonLabel)}
            </SecondaryButton>
          ) : null}
        </div>
        {/* <Link active={state.matches("home")} to="home">
          {t(copy.menuLinkHome)}
        </Link> */}
        {/* {loginLink} */}
      </nav>
      <div className={classes.body}>
        <section className={`sb-theme ${classes.content}`}>
          <Alerts />
          <Router send={send} state={state} />
        </section>
        <footer
          className={classes.footer}
          data-hide={/saad|sn-signup/.test(state.value as string)}
        >
          <div>
            <div>
              {t(copy.copyright, { values: [new Date().getFullYear()] })}
            </div>
          </div>

          <div>
            <a
              href="https://www.asurion.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(copy.linkTermsOfUse)}
            </a>
            <a
              href="https://www.asurion.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(copy.linkPrivacyPolicy)}
            </a>
          </div>
        </footer>
      </div>
    </SendContext.Provider>
  );
};

export default BMS;
