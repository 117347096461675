/*
 * @packageDocumentation
 * @module module/login-api
 * @preferred
 */

import { AuthToken, AuthTokenCoder } from "../models/authToken/types";
import { UserCoder, AuthenticatedUser } from "../models/users";

import q, { Failure } from "./q";
import { pipe } from "fp-ts/lib/pipeable";
import * as TE from "fp-ts/lib/TaskEither";
import fetch from "./fetch";
import { uuidv4 } from "../utils";

/**
 *
 */
export type UserInformationParams = {
  companyId: string;
  username: string;
  token: string;
};

/**
 *
 */
export const qAuthToken = q(AuthTokenCoder);
/**
 *
 */
export const qUser = q(UserCoder);

/**
 *
 */
export const TOKEN_URL = `${process.env.REACT_APP_API_BASE_PATH}/authentication/token`;

/**
 *
 */
export const USER_INFO_URL = `${process.env.REACT_APP_API_BASE_PATH}/operations/administration/v1/userprofile`;

/**
 *
 */
export type Credentials = {
  companyId: string;
  username: string;
  password: string;
};

/**
 *
 * @param credential
 */
function encodeCredentials(credential: Credentials) {
  return btoa(
    `${credential.companyId}|${credential.username}:${credential.password}`
  );
}

/**
 *
 */
export const requestAuthenticationToken = async (creds: Credentials) => {
  return fetch(TOKEN_URL, {
    headers: {
      "x-api-key":
        process.env.REACT_APP_X_API_KEY ||
        "yYPmit3v6t7JnzUulgqeMarf93sYQix49So49uSe",
      Authorization: `Basic ${encodeCredentials(creds)}`,
    },
  });
};

/**
 *
 */
export const requestUserInformation = async (
  _params: UserInformationParams
) => {
  const url = new URL(USER_INFO_URL);

  const params = {
    companyNumber: _params.companyId,
    userAccountId: _params.username,
  };

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    headers: {
      // "Content-Type": "application/json",
      sessionId: uuidv4(),
      sender: "SBXWEBUI",
      correlationId: "authentication",
      companyId: _params.companyId,
      username: _params.username,
      "x-api-key":
        process.env.REACT_APP_X_API_KEY ||
        "yYPmit3v6t7JnzUulgqeMarf93sYQix49So49uSe",
      Authorization: `Bearer ${_params.token}`,
    },
  });
};

export const makeAuthenticationTokenTask = (
  creds: Credentials
): TE.TaskEither<Failure, AuthToken> => () =>
  qAuthToken(() => requestAuthenticationToken(creds));

/**
 * Creates
 * @param params
 */
export const makeUserInformationTask = (
  params: UserInformationParams
): TE.TaskEither<Failure, AuthenticatedUser> =>
  pipe(
    () =>
      qUser(() =>
        requestUserInformation({
          companyId: params.companyId,
          username: params.username,
          token: params.token,
        })
      ),
    TE.map((user) => {
      return {
        ...user,
        companyId: params.companyId,
        username: params.username,
      };
    })
  );

/**
 * Request authentication given a set of credentials.
 *
 */
export const login = (creds: Credentials) =>
  pipe(
    makeAuthenticationTokenTask(creds),
    TE.chain((token) =>
      pipe(
        makeUserInformationTask({
          companyId: creds.companyId,
          username: creds.username,
          token: token.access_token,
        }),
        TE.map((user) => {
          return [user, token] as [typeof user, typeof token];
        })
      )
    )
  );
