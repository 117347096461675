/**
 * @packageDocumentation
 * @module models/user
 */
import * as t from "io-ts";

/**
 * Runtime type decoder for user models.
 *
 * Example:
 *
 * ```ts
 * const fetchUser = async (): Promise<Either<Errors, User>> => {
 *    const response = await fetch("/user/4")
 *    const json = await response.json()
 *
 *    const userOrError: Either<Errors, User> = User.decode(json)
 *
 *    return userOrError
 * }
 * ```
 *
 */
export const UserCoder = t.type(
  {
    userFirstname: t.string,
    userLastname: t.string,
    userEmail: t.union([t.string, t.null]),
    phoneNumber: t.union([t.string, t.null]),
    locale: t.string,
    defaultUserLocationId: t.union([t.number, t.string, t.null]),
  },
  "UserCoder"
);

/**
 * User
 *
 * ```ts
 * type User = {
 *  userFirstname: string
 *  userLastname: string
 *  useruserEmailame: string
 *  phoneNumber: string
 *  locale: string
 *  defaultUserLocationId: number | null
 * }
 * ```
 */
export type User = t.TypeOf<typeof UserCoder>;
