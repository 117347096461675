/**
 * @packageDocumentation
 * @module authToken
 */
import * as t from "io-ts";

/**
 * Runtime type decoder for user models.
 *
 * Example:
 *
 * ```ts
 * const fetchUser = async (): Promise<Either<Errors, User>> => {
 *    const response = await fetch("/user/4")
 *    const json = await response.json()
 *
 *    const userOrError: Either<Errors, User> = User.decode(json)
 *
 *    return userOrError
 * }
 * ```
 *
 */
export const AuthTokenCoder = t.type(
  {
    access_token: t.string,
    refresh_token: t.string,
  },
  "AuthToken"
);

/**
 * AuthToken
 *
 * ```ts
 * type AuthToken = {
 *  access_token: string
 *  refresh_token: string
 * }
 * ```
 */
export type AuthToken = t.TypeOf<typeof AuthTokenCoder>;
