import classes from "./bms.module.scss";

import * as React from "react";

export const Bottom = (props: {
  children: React.ReactNode;
  smallScreen?: boolean;
  padded?: boolean;
}) => (
  <div
    className={classes.bottom}
    data-padded={!!props.padded}
    data-small-screen={!!props.smallScreen}
  >
    <div>{props.children}</div>
  </div>
);

export default Bottom;
