import * as React from "react";

const style = (status?: "warning" | "error" | "success") => ({
  padding: 8,
  backgroundColor:
    status === "error"
      ? "var(--mdc-theme-error, Red)"
      : status === "success"
      ? "#37E7A7"
      : status === "warning"
      ? "Yellow"
      : "#000",
  color: !status || status === "error" ? "#fff" : "#000",
});

interface PropsAlert {
  status?: "warning" | "error" | "success";
  children: React.ReactNode;
}

export const Alert = (props: PropsAlert) => (
  <div style={style(props.status)}>{props.children}</div>
);
