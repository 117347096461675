import {
  EventObject,
  InterpreterOptions,
  Interpreter,
  State,
  StateMachine,
  MachineOptions,
  StateConfig,
} from "xstate";
import { useMachine } from "@xstate/react";

type RemoveEventsWithPayload<T extends { type: string }> = T extends any
  ? keyof Omit<T, "type"> extends never
    ? T["type"]
    : never
  : never;

type TypeOnlyEvent<T extends EventObject> = RemoveEventsWithPayload<T>;

// Interpreter['send']
export interface Send<TEvent extends EventObject> {
  (event: TEvent | TypeOnlyEvent<TEvent>): void;
  <T extends Exclude<TEvent, { type: TypeOnlyEvent<TEvent> }>>(
    event: T["type"],
    payload: Omit<T, "type">
  ): void;
}

interface UseMachineOptions<TContext, TEvent extends EventObject> {
  /**
   * If provided, will be merged with machine's `context`.
   */
  context?: Partial<TContext>;
  /**
   * If `true`, service will start immediately (before mount).
   */
  immediate: boolean;
  /**
   * The state to rehydrate the machine to. The machine will
   * start at this state instead of its `initialState`.
   */
  state?: StateConfig<TContext, TEvent>;
}

interface UseMachine {
  <TContext, TEvent extends EventObject>(
    machine: StateMachine<TContext, any, TEvent>,
    options?: Partial<InterpreterOptions> &
      Partial<UseMachineOptions<TContext, TEvent>> &
      Partial<MachineOptions<TContext, TEvent>>
  ): [
    State<TContext, TEvent>,
    Send<TEvent>,
    Interpreter<TContext, any, TEvent>
  ];
}

export default useMachine as UseMachine;
