/**
 * @packageDocumentation
 * @module router
 */
import * as React from "react";

import { SendContext, StateMachineContext } from "../../components/SendContext";

/**
 * Render prop function used by [[Redirect]]
 * @internal
 */
export const RedirectWithSend = (
  props: PropsRedirect & Pick<StateMachineContext, "send">
) => {
  const { to, send, after } = props;

  React.useEffect(() => {
    console.warn(`Redirecting to: ${to}`);
    after
      ? setTimeout(
          () => send({ type: "GOTO", route: `${to}`, redirected: true }),
          after
        )
      : send({ type: "GOTO", route: `${to}`, redirected: true });
  }, [to, after, send]);

  return null;
};

/**
 * React prop types for the [[Redirect]] component.
 */
export interface PropsRedirect {
  /** Path or url to redirect to */
  to: string;
  after?: number;
}

/**
 * Transitions the router's state machine to a given path or url.
 * @param props See [[PropsRedirect]]
 */
const Redirect = (props: PropsRedirect) => {
  const { send } = React.useContext(SendContext);

  return <RedirectWithSend {...props} send={send} />;
};

export default Redirect;
